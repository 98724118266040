import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import TabView from '../TabView.vue'

import { asyncSettings } from '@/settings'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/onboarding',
    component: () => import('@/views/Onboarding.vue'),
    beforeEnter: async (_to, _from, next) => {
      const settings = await asyncSettings
      if (!settings.value.onboarding) {
        next('/alle-kapitel')
        return
      }
      next()
    }
  },
  {
    path: '/',
    component: TabView,
    beforeEnter: async (_to, _from, next) => {
      const settings = await asyncSettings
      if (settings.value.onboarding) {
        next('/onboarding')
        return
      }
      next()
    },
    children: [
      {
        path: '',
        component: () => import('@/SearchView.vue'),
        children: [
          {
            path: '/',
            redirect: '/alle-kapitel'
          },
          {
            path: 'alle-kapitel',
            component: () => import('@/views/Chapters.vue')
          },
          {
            path: 'favoriten',
            component: () => import('@/views/Favorites.vue')
          },
          {
            path: 'kapitel/:id',
            name: 'ChapterDetail',
            component: () => import('@/views/ChapterDetail.vue')
          }
        ]
      },
      {
        path: 'sportverein',
        component: () => import('@/views/SportsClub.vue')
      },
      {
        path: 'info',
        component: () => import('@/views/InfoView.vue'),
        children: [
          {
            path: '',
            component: () => import('@/views/Info.vue')
          },
          {
            path: 'ueber-uns',
            component: () => import('@/views/About.vue')
          },
          {
            path: 'einfache-sprache',
            component: () => import('@/views/SimpleLanguage.vue')
          },
          {
            path: 'impressum',
            component: () => import('@/views/Imprint.vue')
          }
        ]
      },
      {
        path: 'einstellungen',
        component: () => import('@/views/Settings.vue')
      },
      {
        path: 'vokabel/:id',
        name: 'VocabDetail',
        component: () => import('@/views/VocabDetail.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
