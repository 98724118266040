
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonIcon,
  IonPage,
  IonRouterOutlet,
  IonLabel
} from '@ionic/vue'
import { home, star, informationCircle, settings } from 'ionicons/icons'

export default {
  name: 'Tabs',
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    IonLabel
  },
  setup() {
    return {
      home,
      star,
      informationCircle,
      settings
    }
  }
}
