import { Storage } from '@ionic/storage'
import { computed, ref } from 'vue'

let store: Storage | null

const state = ref({
  primaryLanguage: 'ger',
  secondaryLanguages: [] as string[],
  favorites: [] as number[],
  onboarding: true
})

const settings = computed(() => state.value)

async function updateSettings(update: any) {
  state.value = {
    ...state.value,
    ...update
  }

  if (store) {
    await store.set('settings', JSON.stringify(state.value))
  }
}

const asyncSettings = new Storage().create().then(async (s) => {
  store = s

  await s.get('settings').then((settings) => {
    if (settings) {
      state.value = JSON.parse(settings)
    }
  })

  return state
})

export { settings, asyncSettings, updateSettings }
